<template>
  <b-button-group>
    <!-- READ -->
    <template
      v-if="$can('read', modulo)"
    >
      <b-button
        v-if="showBtnRead"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal="modalRead"
        variant="secondary"
        class="btn-sm mt-25 btn-icon rounded mr-50"
        :title="'Ver detalle de '+ title"
      >
        <feather-icon
          icon="EyeIcon"
        />
      </b-button>
    </template>

    <!-- EDITAR -->
    <template
      v-if="showBtnUpdate && $can('update', modulo)"
    >
      <b-button
        variant="secondary"
        class="btn-sm mt-25 btn-icon rounded mr-50"
        :title="'Editar '+ title"
        @click="$emit('processGoToUpdate', data.item)"
      >
        <feather-icon
          icon="Edit3Icon"
        />
      </b-button>
    </template>

    <!-- ELIMINAR -->
    <b-button
      v-if="showBtnDelete && $can('delete', modulo)"
      variant="secondary"
      class="btn-sm mt-25 btn-icon rounded mr-50"
      size="sm"
      @click="$emit('processRemove', data.item.id)"
      :title="'Eliminar '+ title "
    >
      <feather-icon
        icon="Trash2Icon"
      />
    </b-button>

  </b-button-group>
</template>

<script>
import {
  BButton, BButtonGroup, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BButtonGroup,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      default: '',
    },
    modalRead: {
      type: String,
      default: 'false',
    },
  },
  data() {
    return {
      moduloCreado: '',
      showBtnUpdate: true,
      showBtnDelete: true,
      showBtnRead: true,
      showBtnEnviarAhora: true,
      showBtnEnviarErrores: true,
    }
  },
  mounted() {
    this.setBtnes(this.data.item.estado)
  },
  methods: {
    setBtnes(estado) {
      if (estado === 0) { // ERROR
        // * SI ENVIAR A NOTIFICACIONES CON ERRORES
        this.showBtnUpdate = false
        this.showBtnDelete = false
        this.showBtnRead = true
      }
      if (estado === 1) { // PROGRAMADA
        // * SI ENVIAR AHORA
        this.showBtnUpdate = true
        this.showBtnDelete = true
        this.showBtnRead = true
      }
      if (estado === 2) { // ENVIADA
        this.showBtnUpdate = false
        this.showBtnDelete = false
        this.showBtnRead = true
      }

    },
  },
}
</script>
