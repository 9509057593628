<template>
  <b-modal
    :id="nombreModal"
    :title="title"
    centered
    size="lg"
    :hide-footer="true"
  >
        <b-row>

          <!-- Field: Fecha de Envío -->
          <b-col
            cols="3"
            md="3"
          >
            <b-form-group
              label="Fecha de Envío"
              label-class="font-weight-bold"
            >
              {{ formatoDiaMesYear(data.item.fecha_envio) }}
            </b-form-group>
          </b-col>

          <!-- Field: Hora de Envío -->
          <b-col
            cols="3"
            md="3"
          >
            <b-form-group
              label="Hora de Envío"
              label-class="font-weight-bold"
            >
              {{ formatHoraVer(data.item.hora_envio) }}
            </b-form-group>
          </b-col>

          <!-- Field: Tipo de Envío -->
          <b-col
            cols="3"
            md="3"
          >
            <b-form-group
              label="Tipo de Envío"
              label-class="font-weight-bold"
            >
              <b-badge class="mr-25" :variant="data.item.tipo === 1 ? 'light-primary' : 'light-info'">
                {{ data.item.nombreTipo }}
              </b-badge>
            </b-form-group>
          </b-col>

          <!-- Field: Estado Comunicación -->
          <b-col
            cols="3"
            md="3"
          >
            <b-form-group
              label="Estado"
              label-class="font-weight-bold"
            >
              <b-badge v-if="data.item.estado === 0" class="mr-25" variant="danger">
                {{ data.item.nombreEstado}}
              </b-badge>

              <b-badge v-else-if="data.item.estado === 1" class="mr-25" variant="info">
                {{ data.item.nombreEstado}}
              </b-badge>

              <b-badge v-else-if="data.item.estado === 2" class="mr-25" variant="success">
                {{ data.item.nombreEstado}}
              </b-badge>
            </b-form-group>
          </b-col>

          <!-- Field: Cursos -->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Curso(s)"
              label-class="font-weight-bold"
            >
              <template v-for="(curso, index) in data.item.nombreCursos">
                <b-badge class="mr-25" variant="light-secondary">
                  {{ curso }}
                </b-badge>
              </template>
            </b-form-group>
          </b-col>

          <!-- Field: Título -->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Título"
              label-class="font-weight-bold"
            >
              <b class="text-primary">{{ data.item.titulo}}</b>
            </b-form-group>
          </b-col>

          <!-- Field: Detalle -->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Detalle"
              label-class="font-weight-bold"
            >
              {{ data.item.detalle}}
            </b-form-group>
          </b-col>

          <!-- Field: Estados por alumno -->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Estados por Alumno(s)"
              label-class="font-weight-bold"
            >
              <b-table
                striped
                small
                hover
                noCollapse
                responsive
                show-empty
                class="mt-1"
                :items="items"
                :fields="fields"
              >
                <!-- col: RUT -->
                <template #cell(rut)="data">
                  {{ data.item.rut }}-{{ data.item.dv }}
                </template>

                <!-- col: ESTUDIANTES -->
                <template #cell(estudiantes)="data">
                  {{ data.item.nombre }}
                  {{ data.item.segundo_nombre }}
                  {{ data.item.primer_apellido }}
                  {{ data.item.segundo_apellido }}
                </template>

                <!-- col: ESTADO -->
                <template #cell(estado)="data">
                  <b-badge v-if="data.item.estado === 0" class="mr-25" variant="danger">
                    Error de envío
                  </b-badge>
                  <b-badge v-else-if="data.item.estado === 1" class="mr-25" variant="success">
                    Enviada
                  </b-badge>
                </template>
              </b-table>
            </b-form-group>
          </b-col>
        </b-row>
        <colLinea />

        <!-- ACTION BUTTONS -->
        <div class="text-right">

          <b-button
            variant="outline-primary"
            class="mr-25"
            @click="closeModal"
          >
            Salir
          </b-button>
        </div>

  </b-modal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BModal, VBModal, BButton, BFormGroup, BBadge, BTable
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

// FORMATOS
import { formatos } from '@core/mixins/ui/formatos'

// COMPONENTES RECYCLED
import colLinea from '../../components/Form/colLinea.vue'

export default {
  components: {
    // ETIQUETAS
    BRow,
    BCol,
    BModal,
    BButton,
    VBModal,
    BFormGroup,
    BBadge,
    BTable,

    // COMPONENTES RECYCLED
    colLinea,
  },
  mixins: [formatos],
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'rut',
          label: 'Rut',
          sortable: true,
          thStyle: {
            width: '20% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'estudiantes',
          label: 'Estudiantes',
          sortable: true,
          thStyle: {
            width: '40% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'estado',
          // label: 'Notificación',
          label: 'Estado',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            width: '20% !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.setAlumnos(this.data.item.alumnos)
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.nombreModal)
    },
    setAlumnos(alumnos) {
      this.items = []
      this.items = alumnos


    }
  },
}
</script>
